import { constants } from '@wsb/guac-widget-core';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../common/constants/headerTreatments';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL, MVP } = constants.paintJobs;

const id = 'layout9';
const imageTreatments = {
  [FILL]: 'none',
  [FIT]: 'none',
  [INSET]: 'category-solid',
  [BLUR]: 'none',
  [LEGACY_BLUR]: 'category-overlay'
};

const headerTreatmentsConfig = {
  defaultHeaderTreatment: FILL,
  hasLegacy: true,
  imageTreatments
};

export default {
  id,
  name: 'urban',
  packs: {
    color: '#507DE5',
    font: 'righteous'
  },
  logo: {
    font: 'alternate'
  },
  packCategories: {
    color: colorPackCategories.ACCENT
  },
  headerProperties: {
    alignmentOption: 'center'
  },
  headerTreatmentsConfig,
  paintJobs: [LIGHT, MVP, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob: MVP,
  buttons: {
    primary: {
      fill: buttons.fills.SOLID,
      shape: buttons.shapes.SQUARE,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    secondary: {
      fill: buttons.fills.SOLID,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    ...COMMON_BUTTON_CONFIG
  }
};
